
.title-separator {
    .content {
        color: var(--global-color)!important;
    }

    .line {
        background-color:  #ddd!important;
    }

}
